export const HAZARDS_METADATA = {
  river: {
    label: 'River Flooding',
    dataUnit: 'm',
  },
  coastal: {
    label: 'Coastal Flooding',
    dataUnit: 'm',
  },
};

export const HAZARDS_MAP_ORDER = ['river', 'coastal'];
export const HAZARDS_UI_ORDER = ['river', 'coastal'];
