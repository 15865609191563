import { COLORS } from 'config/colors';
import { makeConfig } from 'lib/helpers';

/* Line widths:

-) elec_edges_high,
base: 1,
stops: [
  [7, 1],
  [12, 2],
  [16, 6],
],

-) elec_edges_low, pot_edges
base: 0.5,
stops: [
  [7, 0.5],
  [12, 1],
  [16, 3],
],

-) rail_edges
base: 1.5,
stops: [
  [7, 1.5],
  [12, 2],
  [16, 6],
],

-) road_edges
base: 0.5,
stops: [
  [7, 1.5],
  [12, 2],
  [16, 6],
],

-) all circle layers
base: 1.5,
stops: [
  [7, 3],
  [12, 4],
  [16, 12],
],

*/

export const NETWORKS_METADATA = makeConfig([
  {
    id: 'elec_edges_high',
    type: 'line',
    label: 'Power Lines (High Voltage)',
    color: COLORS.electricity_high.css,
  },
  {
    id: 'elec_edges_low',
    type: 'line',
    label: 'Power Lines (Low Voltage)',
    color: COLORS.electricity_low.css,
  },
  {
    id: 'elec_nodes_diesel',
    type: 'circle',
    label: 'Power Generation (Diesel)',
    color: COLORS.electricity_high.css,
  },
  {
    id: 'elec_nodes_gas',
    type: 'circle',
    label: 'Power Generation (Gas)',
    color: COLORS.electricity_high.css,
  },
  {
    id: 'elec_nodes_hydro',
    type: 'circle',
    label: 'Power Generation (Hydro)',
    color: COLORS.electricity_high.css,
  },
  {
    id: 'elec_nodes_solar',
    type: 'circle',
    label: 'Power Generation (Solar)',
    color: COLORS.electricity_high.css,
  },
  {
    id: 'elec_nodes_wind',
    type: 'circle',
    label: 'Power Generation (Wind)',
    color: COLORS.electricity_high.css,
  },
  {
    id: 'elec_nodes_pole',
    type: 'circle',
    label: 'Power Transmission (Poles)',
    color: COLORS.electricity_unknown.css,
  },
  {
    id: 'elec_nodes_substation',
    type: 'circle',
    label: 'Power Transmission (Substations)',
    color: COLORS.electricity_unknown.css,
  },
  {
    id: 'elec_nodes_demand',
    type: 'circle',
    label: 'Power Demand',
    color: COLORS.electricity_low.css,
  },
  {
    id: 'rail_edges_open',
    type: 'line',
    label: 'Rail (open)',
    color: COLORS.railway.css,
  },
  {
    id: 'rail_edges_disused',
    type: 'line',
    label: 'Rail (disused)',
    color: COLORS.rail_past.css,
  },
  {
    id: 'rail_edges_rehabilitation',
    type: 'line',
    label: 'Rail (rehabilitation)',
    color: COLORS.rail_past.css,
  },
  {
    id: 'rail_edges_construction',
    type: 'line',
    label: 'Rail (construction)',
    color: COLORS.rail_future.css,
  },
  {
    id: 'rail_edges_abandoned',
    type: 'line',
    label: 'Rail (abandoned)',
    color: COLORS.rail_past.css,
  },
  {
    id: 'rail_edges_proposed',
    type: 'line',
    label: 'Rail (proposed)',
    color: COLORS.rail_future.css,
  },
  {
    id: 'rail_nodes_station',
    type: 'circle',
    label: 'Stations',
    color: COLORS.railway.css,
  },
  {
    id: 'rail_nodes_stop',
    type: 'circle',
    label: 'Railway Stop',
    color: COLORS.railway.css,
  },
  {
    id: 'rail_nodes_halt',
    type: 'circle',
    label: 'Railway Halt',
    color: COLORS.railway.css,
  },
  {
    id: 'road_edges_motorway',
    type: 'line',
    label: 'Roads (motorway)',
    color: COLORS.roads_motorway.css,
  },
  {
    id: 'road_edges_trunk',
    type: 'line',
    label: 'Roads (trunk)',
    color: COLORS.roads_trunk.css,
  },
  {
    id: 'road_edges_primary',
    type: 'line',
    label: 'Roads (primary)',
    color: COLORS.roads_primary.css,
  },
  {
    id: 'road_edges_secondary',
    type: 'line',
    label: 'Roads (secondary)',
    color: COLORS.roads_secondary.css,
  },
  {
    id: 'road_edges_tertiary',
    type: 'line',
    label: 'Roads (tertiary)',
    color: COLORS.roads_tertiary.css,
  },
  {
    id: 'road_bridges',
    type: 'circle',
    label: 'Bridges',
    color: COLORS.bridges.css,
  },
  {
    id: 'air_nodes',
    type: 'circle',
    label: 'Airports',
    color: COLORS.airports.css,
  },
  {
    id: 'port_nodes_maritime',
    type: 'circle',
    label: 'Maritime',
    color: COLORS.ports.css,
  },
  {
    id: 'port_nodes_lake',
    type: 'circle',
    label: 'Lake',
    color: COLORS.ports.css,
  },
  {
    id: 'water_potable_edges',
    type: 'line',
    label: 'Water Supply Pipelines',
    color: COLORS.water_supply.css,
  },
  {
    id: 'water_potable_nodes_booster',
    label: 'Water Supply (Booster Station)',
    type: 'circle',
    color: COLORS.water_supply.css,
  },
  {
    id: 'water_potable_nodes_catchment',
    label: 'Water Supply (Catchment)',
    type: 'circle',
    color: COLORS.water_supply.css,
  },
  {
    id: 'water_potable_nodes_entombment',
    label: 'Water Supply (Entombment)',
    type: 'circle',
    color: COLORS.water_supply.css,
  },
  {
    id: 'water_potable_nodes_filter',
    label: 'Water Supply (Filter Plant)',
    type: 'circle',
    color: COLORS.water_supply.css,
  },
  {
    id: 'water_potable_nodes_intake',
    label: 'Water Supply (Intake)',
    type: 'circle',
    color: COLORS.water_supply.css,
  },
  {
    id: 'water_potable_nodes_well',
    label: 'Water Supply (Production Well)',
    type: 'circle',
    color: COLORS.water_supply.css,
  },
  {
    id: 'water_potable_nodes_pump',
    label: 'Water Supply (Pump Station)',
    type: 'circle',
    color: COLORS.water_supply.css,
  },
  {
    id: 'water_potable_nodes_relift',
    label: 'Water Supply (Relift Station)',
    type: 'circle',
    color: COLORS.water_supply.css,
  },
  {
    id: 'water_potable_nodes_reservoir',
    label: 'Water Supply (Reservoir)',
    type: 'circle',
    color: COLORS.water_supply.css,
  },
  {
    id: 'water_potable_nodes_river_source',
    label: 'Water Supply (River Source)',
    type: 'circle',
    color: COLORS.water_supply.css,
  },
  {
    id: 'water_potable_nodes_spring',
    label: 'Water Supply (Spring)',
    type: 'circle',
    color: COLORS.water_supply.css,
  },
  {
    id: 'water_potable_nodes_tank',
    label: 'Water Supply (Storage Tank)',
    type: 'circle',
    color: COLORS.water_supply.css,
  },
  {
    id: 'water_potable_nodes_sump',
    label: 'Water Supply (Sump)',
    type: 'circle',
    color: COLORS.water_supply.css,
  },
  {
    id: 'water_potable_nodes_tp',
    label: 'Water Supply (Treatment Plant)',
    type: 'circle',
    color: COLORS.water_supply.css,
  },
  {
    id: 'water_irrigation_edges',
    type: 'line',
    label: 'Irrigation Canals',
    color: COLORS.water_irrigation.css,
  },
  {
    id: 'water_irrigation_nodes',
    type: 'circle',
    label: 'Irrigation facilities',
    color: COLORS.water_irrigation.css,
  },
  {
    id: 'water_waste_sewer_gravity',
    type: 'line',
    label: 'Wastewater Pipelines (Gravity)',
    color: COLORS.water_wastewater.css,
  },
  {
    id: 'water_waste_sewer_pressure',
    type: 'line',
    label: 'Wastewater Pipelines (Pressure)',
    color: COLORS.water_wastewater.css,
  },
  {
    id: 'water_waste_nodes_sump',
    type: 'circle',
    label: 'Wastewater (Sump)',
    color: COLORS.water_wastewater.css,
  },
  {
    id: 'water_waste_nodes_pump',
    type: 'circle',
    label: 'Wastewater (Pump Station)',
    color: COLORS.water_wastewater.css,
  },
  {
    id: 'water_waste_nodes_relift',
    type: 'circle',
    label: 'Wastewater (Relift Station)',
    color: COLORS.water_wastewater.css,
  },
  {
    id: 'water_waste_nodes_wwtp',
    type: 'circle',
    label: 'Wastewater (Treament Plant)',
    color: COLORS.water_wastewater.css,
  },
  {
    id: 'buildings_commercial',
    type: 'polygon',
    label: 'Buildings (Commercial)',
    color: COLORS.buildings_commercial.css,
  },
  {
    id: 'buildings_industrial',
    type: 'polygon',
    label: 'Buildings (Industrial)',
    color: COLORS.buildings_industrial.css,
  },
  {
    id: 'buildings_institutional',
    type: 'polygon',
    label: 'Buildings (Institutional)',
    color: COLORS.buildings_institutional.css,
  },
  {
    id: 'buildings_mixed',
    type: 'polygon',
    label: 'Buildings (Mixed Use)',
    color: COLORS.buildings_mixed.css,
  },
  {
    id: 'buildings_other',
    type: 'polygon',
    label: 'Buildings (Other)',
    color: COLORS.buildings_other.css,
  },
  {
    id: 'buildings_recreation',
    type: 'polygon',
    label: 'Buildings (Recreation)',
    color: COLORS.buildings_recreation.css,
  },
  {
    id: 'buildings_residential',
    type: 'polygon',
    label: 'Buildings (Residential)',
    color: COLORS.buildings_residential.css,
  },
  {
    id: 'buildings_resort',
    type: 'polygon',
    label: 'Buildings (Resort)',
    color: COLORS.buildings_resort.css,
  },
]);
